<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="row">
            <div class="left">
                <div class="col">
                    <span class="type">类型选择：</span>
                    <el-select v-model="type" placeholder="请选择" @change="(pageNum = 1), getData()">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="create" @click="dialogVisible = true">创建+</div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, idx) in list" :key="idx" @click="$router.push({ path: '/civilizedCreationDetail', query: { id: item.id } })">
                <img v-if="item.picUrl" class="active" :src="item.picUrl" alt="" />
                <img v-else src="@/assets/images/default_pic.png" class="active" />
                <div class="info">
                    <div class="title">{{ item.createTitle }}</div>
                    <div class="calligraphy">{{ options[item.createType].label }}</div>

                    <div class="address">
                        <span>{{ item.craeteDec }}</span>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="pageNum" :page-sizes="[12, 20, 30, 50]" :page-size.sync="pageSize" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
        <el-dialog title="文化创作" :visible.sync="dialogVisible" width="600px" append-to-body class="dialog">
            <el-form ref="form1" label-position="top" label-width="80px" :model="form" :rules="rules">
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="类型：" prop="options">
                    <el-select v-model="form.options" placeholder="请选择" style="width: 100%">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="描述：" prop="content">
                    <el-input type="textarea" rows="5" placeholder="请输入内容" resize="none" v-model="form.content"></el-input>
                </el-form-item>
                <div class="item uploads">
                    <div>
                        <img src="@/assets/images/Frame652.png" alt="" />
                        <span class="title">图片：</span>
                    </div>
                    <el-upload action="#" list-type="picture-card" :http-request="upDate" :file-list="pictures" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :limit="3">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div class="item uploads">
                    <div>
                        <img src="@/assets/images/Frame652.png" alt="" />
                        <span class="title">视频：</span>
                    </div>
                    <el-upload action="#" list-type="picture-card" :http-request="upDateVideo" :file-list="videos" :limit="3">
                        <i class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{ file }">
                            <video :src="file.url" @click.stop="" controls></video>
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-delete" @click="handleRemoveVideo(file)">
                                    <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('form1')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible1" append-to-body class="dialog1">
            <img width="50%" :src="dialogImageUrl" alt="" />
        </el-dialog>

        <!--      <el-pagination @size-change="getData" @current-change="getData" :current-page.sync="pageNum" :page-size.sync="pageSize" :page-sizes="[12, 24, 36, 48]" class="pagination" background layout="prev, pager, next,jumper" :total="total"></el-pagination>-->
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import uploadFile from '@/utils/oss/ossClient';
export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '发现',
                },
                {
                    title: '文明创作',
                    to: { path: '/civilizedCreation' },
                },
            ],
            options: [
                {
                    label: '全部',
                    value: '',
                },
                {
                    label: '书法',
                    value: 1,
                },
                {
                    label: '舞蹈',
                    value: 2,
                },
                {
                    label: '音乐',
                    value: 3,
                },
                {
                    label: '美术',
                    value: 4,
                },
                {
                    label: '摄影',
                    value: 5,
                },
                {
                    label: '文艺鉴赏',
                    value: 6,
                },
                {
                    label: '其他',
                    value: 7,
                },
            ],
            type: '',
            list: [],
            pageNum: 1,
            pageSize: 16,
            total: 0,
            dialogVisible: false,
            form: {
                title: '',
                options: '',
                content: '',
                picUrl: '',
                videoUrl: '',
            },
            rules: {
                title: [
                    {
                        required: true,
                        message: '请输入标题',
                        trigger: 'blur',
                    },
                ],
                options: [
                    {
                        required: true,
                        message: '请选择活动区域',
                        trigger: 'change',
                    },
                ],
                content: [
                    {
                        required: true,
                        message: '请输入活动内容',
                        trigger: 'blur',
                    },
                ],
            },
            dialogImageUrl: '',
            dialogVisible1: false,
            dialogVideoUrl: '',
            videos: [],
            pictures: [],
        };
    },
    watch: {
        type() {
            this.pageNum = 1;
            this.getData();
        },
    },
    created() {
        this.getData();
    },
    methods: {
        submitForm(form1) {
            if (this.videos.length === 0 && this.pictures.length === 0) {
                this.$message.error('请上传图片或视频');
                return;
            }
            this.$refs[form1].validate((valid) => {
                if (valid) {
                    this.dialogVisible = false;
                    this.$hao
                        .addCreation({
                            createTitle: this.form.title,
                            createType: this.form.options,
                            picUrl: this.pictures.map((item) => item.url).join(','),
                            videoUrl: this.videos.map((item) => item.url).join(','),
                            craeteDec: this.form.content,
                            libraryId: localStorage.getItem('id'),
                        })
                        .then((res) => {
                            if (res.code === 200) {
                                this.$message({
                                    message: '创建成功',
                                    type: 'success',
                                });
                            }
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        upDate(e) {
            uploadFile(e).then((res) => {
                this.pictures.push({ uid: e.file.uid, url: res[0] });
            });
        },
        handleRemove(file, fileList) {
            this.pictures = fileList;
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible1 = true;
        },
        upDateVideo(e) {
            uploadFile(e).then((res) => {
                this.videos.push({ uid: e.file.uid, url: res[0] });
            });
        },
        handleRemoveVideo(file) {
            let index = this.videos.findIndex((item) => {
                if (item.uid == file.uid) {
                    return true;
                }
            });
            this.videos.splice(index, 1);
        },
        getData() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            if (this.type !== '') {
                params.createType = this.type;
            }
            this.$hao.getCivilizedList(params).then((res) => {
                console.log(res);
                let arr = res.rows.map((item) => {
                    if (item.picUrl) {
                        item.picUrl = item.picUrl.split(',')[0];
                    }
                    return item;
                });
                this.list = arr;
                this.total = res.total;
            });
        },
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.box {
    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .col {
                display: flex;
                align-items: center;
                margin-right: 24px;

                span {
                    font-size: 13px;
                    color: #333333;
                }

                .el-select {
                    width: 160px;
                }

                ::v-deep .el-input__inner {
                    border: 0;
                }
            }
        }

        .create {
            background: #f33f3e;
            border-radius: 2px;
            width: 63px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            color: #ffffff;
        }

        .right {
            display: flex;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
                margin-right: 24px;
            }

            ::v-deep .el-input__inner,
            ::v-deep .el-input {
                border-radius: 20px;
                width: 240px;
                height: 30px;
            }

            ::v-deep .el-input__prefix {
                margin-top: -3px;
                //i {
                //    height: 30px;
                //}
            }
        }
    }

    .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 16px;
        width: 100%;

        .item {
            display: flex;
            flex-direction: column;
            page-break-inside: avoid;
            width: 100%;
            background: #ffffff;
            border: 1px solid #ebebeb;
            border-radius: 4px;
            margin-top: 24px;
            position: relative;

            .active {
                width: 100%;
                height: 210px;
            }

            .info {
                padding: 12px;

                .title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 23px;
                    height: 46px;
                    color: #333333;
                    width: 100%;
                    .ellipsisLine(2);
                }

                .status {
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 16px;
                    line-height: 23px;
                    color: #ffffff;
                    padding: 4px 12px;
                    background: #f33f3e;
                    border-radius: 2px 4px;
                }

                .calligraphy {
                    padding: 2px 5px;
                    border: 1px solid #f33f3e;
                    border-radius: 2px;
                    font-size: 12px;
                    color: #f33f3e;
                    display: inline-block;
                }

                .address {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    span {
                        font-size: 13px;
                        line-height: 19px;
                        color: #999999;
                        //  超出一行隐藏
                        .ellipsisLine(1);
                    }
                }

                .reading {
                    margin-top: 10px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    color: #666666;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }

                .go {
                    margin-top: 10px;
                    height: 44px;
                    box-shadow: 0px 2px 4px rgba(14, 79, 71, 0.2);
                    background: #eda200;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: #ffffff;
                }
            }
        }
    }

    .pagination {
        margin: 20px auto;
    }
}

.dialog {
    padding-top: 8px;

    .item {
        // display: flex;
        flex-direction: column;

        ::v-deep .el-input__inner {
            border-radius: 4px;
        }

        div {
            margin-bottom: 12px;

            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                flex: none;
                order: 0;
                flex-grow: 0;
            }

            img {
                width: 7px;
                height: 17px;
            }
        }
    }

    .uploads {
        ::v-deep .el-upload--picture-card {
            margin: 0 10px 10px 0;
            width: 130px;
            height: 130px;
            line-height: 131px;
        }

        ::v-deep .el-upload-list__item {
            width: 130px;
            height: 130px;
        }

        .videos {
            display: inline;
            position: relative;
            float: left;
        }

        video {
            width: 130px;
            height: 130px;
            margin-right: 10px;
        }
    }
}

.dialog1 {
    text-align: center;
}
</style>
